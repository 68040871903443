import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { posts as postsApi } from "../../api/posts";
import RoundImage from "../../component/Image/RoundImage";
import Video from "../../component/Video/Video";
import { PLACEHOLDER, PLACEHOLDER_CHALLENGE } from "../../helpers/constant";
import Button from "../../component/Button/Button";
import Icon from "../../component/Icon/Icon";
import PostStats from "./PostStats";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ConfirmModal from "../../component/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import Image from "../../component/Image/Image";

const UserChallengeCard = (props) => {
    const { challenge, onDelete } = props;
    const user = useSelector((store) => store.user.user);
    const dispatch = useDispatch();
    const history = useHistory();

    // const postActions = useRef([]);
    const [postActions, setPostActions] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        if (challenge?.post?.created_by?.id == user.id) {
            setPostActions((p) => [
                ...p,
                {
                    title: "Edit Challenge",
                    subText: "Edit your challenge",
                    icon: "icofont-edit",
                    action: () => {
                        history.push(`/challenge/${challenge.id}/edit`);
                    },
                },
                {
                    title: "Delete Challenge",
                    subText: "Delete Challenge",
                    icon: "icofont-ui-delete",
                    action: () => {
                        // deleteChallenge();
                        setShowConfirm(true);
                    },
                },
            ]);
        }
    }, [dispatch]);

    const deleteChallenge = useCallback(() => {
        dispatch(
            postsApi({
                method: "DELETE",
                param_path: challenge.post.id.toString(),
            }),
        )
            .then((response) => {
                if (response.success) {
                    toast.success("Challenge Deleted");
                    onDelete(challenge.id);
                    setShowConfirm(false);
                }
            })
            .catch((err) => {
                setShowConfirm(false);
                console.log(err);
            });
    });

    // {
    //     title: "Share",
    //     subText: "Share this to post friends",
    //     icon: "icofont-share-alt",
    //     action: () => {
    //     }
    // },
    // {
    //     title: "Send Message",
    //     subText: "Send this in messages,groups",
    //     icon: "icofont-ui-text-chat",
    //     action: () => {

    //     }
    // },
    // {
    //     title: "Delete Post",
    //     subText: "If inappropriate post by mistake",
    //     icon: "icofont-ui-delete",
    //     action: () => {

    //     }
    // },]
    // {
    //     title: "Report",
    //     subText: "Inappropriate content",
    //     icon: "icofont-flag",
    //     action: () => {

    //     }
    // },]

    return (
        <>
            <ConfirmModal
                showModal={showConfirm}
                title="Confirm"
                message={`Are you sure you want to delete '${challenge?.title}'?`}
                onConfirm={deleteChallenge}
                onClose={() => setShowConfirm(false)}
            />
            <div className="col-md-7 p-0">
                {challenge?.post?.uri ? (
                <Video postId={challenge?.post?.id} src={challenge?.post.uri} style={{ width: "100%", height: "250px", borderRadius: "10px", objectFit: "cover" }} />
                ) : (
                <Image src={ PLACEHOLDER_CHALLENGE } style={{ width: "100%", height: "250px", borderRadius: "10px", objectFit: "cover" }} />
                )}
            </div>
            <div className="col-md-5">
                <div className="user-post py-3">
                    <div className="row">
                        <div className="col-8">
                            <RoundImage src={challenge?.post?.created_by?.profile_pic || PLACEHOLDER} alt={challenge?.post?.title} size="50px" />
                            <div className="friend-name d-inline-block">
                                <ins>
                                    <a href="#">{challenge?.post?.created_by?.name}</a>
                                </ins>
                                <span>@{challenge?.post?.created_by?.username}</span>
                            </div>
                        </div>
                        <div className="col-4">
                            {user?.id == challenge?.post?.created_by?.id && (
                                <div className="more">
                                    <div className="more-post-optns">
                                        <i>
                                            <svg
                                                className="feather feather-more-horizontal"
                                                strokeLinejoin="round"
                                                strokeLinecap="round"
                                                strokeWidth={2}
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                height={24}
                                                width={24}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle r={1} cy={12} cx={12} />
                                                <circle r={1} cy={12} cx={19} />
                                                <circle r={1} cy={12} cx={5} />
                                            </svg>
                                        </i>
                                        <ul>
                                            {postActions.map((a, index) => {
                                                return (
                                                    <li key={`${a.icon}-${index}`}>
                                                        <Button onClick={() => a.action()} type="link" style={{ textAlign: "left" }}>
                                                            <i className={`${a.icon}`}></i> {a.title}
                                                            <span>{a.subText}</span>
                                                        </Button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-12">
                            <div className="post-meta">
                                <Link to={`/challenge/${challenge?.id}/accept`}>
                                    <p className="mb-0 text-truncate">Created a challenge: {challenge?.title}</p>
                                    <p className="mb-0 text-truncate">Description: {challenge?.description}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="friend-info">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-10"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default UserChallengeCard;
