import React, { useRef, useState } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getFormData } from "../helpers/utility";
import { users as usersApi } from "../api/users";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessages } from "../helpers/validationMessages";
import { setCurrentUser } from "../redux/actions/user";
import { toast } from 'react-toastify';
import Button from "../component/Button/Button";

const AddCardForm = (props) => {
    const addCardBtn = useRef();
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [errors, setErrors] = useState({});

  const handleCardSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      toast.error('Something went wrong!');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const pmResponse = await stripe.createToken(cardElement);

      if (pmResponse.error) {
        toast.info(pmResponse.error.message);
        return;
      }

        // Handle success, e.g., save the token to your server or perform other actions
        let data = {
            brand: pmResponse.token.card.brand,
            number: pmResponse.token.card.last4,
            exp_month: pmResponse.token.card.exp_month,
            exp_year: pmResponse.token.card.exp_year,
            cvc: '111',
            stripe_pm_id: pmResponse.token.card.id,
            // source: result
        };
        // addCardBtn.current.showLoader(true);
        
        const params = {
            data: {
                user_id: props?.auth_user?.id,
                name: data.card_name,
                card: data,
                source: pmResponse.token.id
            },
            param_path: props?.auth_user?.id + "/user_card_details",

            headers: {
                "Content-Type": "multipart/form-data",
            },
            method: "POST",
        };

        params.data = getFormData(params.data);

        dispatch(usersApi(params))
            .then((response) => {
                // addCardBtn.current?.showLoader(false);
                if (response.success) {
                    props.addCard(response.data.data);

                    dispatch(setCurrentUser(response.data.user));
                    toast.success(response.message);
                }
            })
            .catch((e) => {
                // addCardBtn.current?.showLoader(false);
                console.log(e)
                if (!e.response.messages) {
                    toast.error(e.response.data.message);
                    return;
                }
                const t = setErrorMessages(e, true);
                setErrors(t);
            });
        
    } catch (error) {
      console.error('Error creating payment method:', error);
    }
  };

  return (
    <div>
      <form className={`mt-4`} onSubmit={handleCardSubmit}>
        <div className="mx-3">
            <CardElement />
        </div>
        <div className={`row m-auto`}>
          <div className={`mb-0 col-lg-12 text-right`}>
            <button type="submit" ref={addCardBtn} disabled={!stripe} className="btn btn-info mt-3">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const PaymentMethod = (props) => {
  const clientKey = useSelector((store) => store.app.reverieParams.stripe_key);
  const stripePromise = loadStripe(clientKey);

  return (
    <Elements stripe={stripePromise}>
      <AddCardForm auth_user={props.auth_user} addCard={props.addCard} />
    </Elements>
  );
};

export default PaymentMethod;