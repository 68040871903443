import React, { useState } from "react";
import Video from "../../component/Video/Video";
import { Link } from "react-router-dom";
import styles from "./userpostcard.module.css";
import { useSelector } from "react-redux";
import Button from "../../component/Button/Button";
import Icon from "../../component/Icon/Icon";
import { useDispatch } from "react-redux";
import { posts, posts as postsApi } from "../../api/posts";
import { useLocation } from "react-router-dom";
import SquareImage from "../../component/Image/SquareImage";
import { BANNER } from "../../helpers/constant";
import BuyPost from "../BuyPost";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PostStats from "./PostStats";
import ShareModal from "../../component/ShareModal/ShareModal";
import ConfirmModal from "../../component/ConfirmModal/ConfirmModal";
import PaymentMethod from "../PaymentMethod";
import UnSafePostModal from "../../component/UnsafePostModal/UnsafePostModal";

const UserPostCard = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const post = props.post;
    const auth_user = useSelector((store) => store.user.user);
    const [selectedCard, setSelectedCard] = useState(auth_user?.card_details[0]?.id);
    const buyBtn = useRef();
    const [showShareModal, setShowShareModal] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showUnSafePostModal, setShowUnSafePostModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const postActions = [
        // {
        //     title: "Share",
        //     subText: "Share this on Facebook",
        //     icon: "flag",
        //     action: () => setShowShareModal(true),
        // },
    ];

    if (auth_user?.id == post?.created_by?.id) {
        postActions.push({
            title: "Edit Post",
            subText: "Edit this post",
            icon: "ui-edit",
            action: () => {
                history.push(`/post/${post.id}/edit`);
            },
        });
        postActions.push({
            title: "Delete Post",
            subText: "If inappropriate Post By Mistake",
            icon: "ui-delete",
            action: (e, { id }) => {
                e.stopPropagation();

                setShowConfirm(true);
            },
        });
        if (post?.status != "unsafe" && post.is_public == 1 && post?.no_boosts > 0) {
            postActions.push({
                title: "Boost History",
                subText: "View Boost History",
                icon: "chart-histogram",
                action: (e) => {
                    history.push(`/all/boosts/${post?.id}`);
                },
            });
        }
    }

    const deletePost = async (id) => {
        try {
            const params = {
                param_path: id.toString(),
                method: "DELETE",
            };

            const response = await dispatch(postsApi(params));
            if (response.success) {
                toast.success("Post Deleted Successfully");
                props.remove(id);
                setShowConfirm(false);
            }
        } catch (err) {
            setShowConfirm(false);
            console.log(err);
        }
    };

    const showPost = (post) => {
        if (post.visible_to == "everyone" || auth_user.id == post?.created_by?.id) {
            return true;
        }
        if (post.visible_to == "subscribers") {
            if (post.has_subscribed == true || post.has_purchased == true) {
                return true;
            } else {
                return false;
            }
        }

        if (post.visible_to == "buyers") {
            if (post.has_purchased == true) {
                return true;
            } else {
                return false;
            }
        }
    };
    const BuyPostModal = (post) => {
        return <BuyPost post={post} selected-card={selectedCard} set-selected-card={setSelectedCard} />;
    };

    const buyPost = () => {
        buyBtn.current.showLoader(true);
        dispatch(
            posts({
                method: "POST",
                param_path: post.id.toString() + "/buy",
                data: {
                    card_id: selectedCard,
                },
            }),
        ).then((response) => {
            if (response.success) {
                buyBtn.current.showLoader(false);
                if (response.data.has_purchased) {
                    toast.success("Post Purchased Successfully");
                    props.update(response.data);
                    setShowModal(false);
                } else {
                    toast.error("Post Purchase Failed");
                }
            } else {
                buyBtn.current.showLoader(false);
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <Modal size="lg" id="submit-modal" show={showPaymentModal} onHide={() => setShowModal(false)} backdrop="static">
                <Modal.Header className="p-3 border-bottom">
                    <h4 className="modal-title">Add Payment Method</h4>
                    <Button className="close" htmlType="link" onClick={() => setShowPaymentModal(false)}>
                        x
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <PaymentMethod auth_user={auth_user} addCard={() => setShowPaymentModal(false)} />
                </Modal.Body>
            </Modal>
            <Modal size="lg" id="submit-modal" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header className="p-3 border-bottom">
                    <h4 className="modal-title">Buy Post</h4>
                    <Button className=" close" type="link" onClick={() => setShowModal(false)}>
                        x
                    </Button>
                </Modal.Header>
                <Modal.Body>{BuyPostModal(post)}</Modal.Body>
                <Modal.Footer>
                    {auth_user?.card_details?.length > 0 ? (
                        <>
                            <Button ref={buyBtn} type="button" htmlType="button" onClick={buyPost}>
                                Buy{" "}
                            </Button>
                            <Button type="button" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                        </>
                    ) : (
                        <Button type="button" className="btn btn-primary d-block" onClick={() => setShowPaymentModal(true)}>
                            Add Card
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <ConfirmModal showModal={showConfirm} title="Confirm" message={`Are you sure you want to delete this post?`} onConfirm={() => deletePost(post.id)} onClose={() => setShowConfirm(false)} />
            <ShareModal post={post} show={showShareModal} handleClose={() => setShowShareModal(false)} />

            <UnSafePostModal post={post} showModal={showUnSafePostModal} onHide={() => setShowUnSafePostModal(false)} onClose={() => setShowUnSafePostModal(false)} backdrop="static" />

            <div className="my-2 rounded border">
                <div className={`${styles["video-box"]} d-flex flex-column justify-content-center`}>
                    {!showPost(post) && (
                        <div className="port-thumbnail">
                            <SquareImage src={post.thumbnail} height="250px" width="100%" />
                        </div>
                    )}
                    {showPost(post) && <Video poster={post?.thumbnail} src={post?.uri} style={{ height: "250px", objectFit: "cover" }} />}
                    {post?.status == "unsafe" && (
                        <span
                            className="badge badge-danger float-left cursor-pointer"
                            style={{
                                position: "absolute",
                                top: "20px",
                                left: "10px",
                            }}
                            onClick={() => setShowUnSafePostModal(true)}
                        >
                            <Icon name={`info-circle`} className={`mr-1`} />
                            Unsafe
                        </span>
                    )}
                    <div className={`${styles["btnClass"]}`}>
                        {post?.status == "unsafe" ? "" : auth_user.id == post?.created_by?.id && post?.visible_to == "everyone" && post?.is_public == 1 && <Link to={`/boostpost/${post.id}`}>Boost</Link>}
                        {/*test*/}
                        {/* {auth_user.id == post?.created_by?.id && post.is_boosted && <Link to={`/boosts/${post?.current_boost?.id}`}>Boosted</Link>} */}
                        {!showPost(post) && (
                            <Button type="button" onClick={() => setShowModal(true)}>
                                Buy
                            </Button>
                        )}
                    </div>
                </div>
                <div className="p-2">
                    <div className="row">
                        <span className="font-weight-bold col-md-8 text-left text-truncate">
                            <Link to={`/post/${post.id}`}>{post.content}</Link>
                        </span>
                        {postActions.length > 0 && (
                            <div className="more col-md-4 text-right">
                                <div className="more-post-optns">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-more-horizontal"
                                    >
                                        <circle cx={12} cy={12} r={1} />
                                        <circle cx={19} cy={12} r={1} />
                                        <circle cx={5} cy={12} r={1} />
                                    </svg>
                                    <ul>
                                        {postActions.map((a, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <li key={`${a.icon}-${index}`}>
                                                        <Button
                                                            onClick={(e) =>
                                                                a.action(e, {
                                                                    id: props.post?.id,
                                                                })
                                                            }
                                                            type="link"
                                                            style={{
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            <Icon name={a.icon} /> {a.title}
                                                            <span>{a.subText}</span>
                                                        </Button>
                                                    </li>
                                                </React.Fragment>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )}
                        <div className="col-md-12 text-center">
                            <PostStats post={post} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default UserPostCard;
