import React, { useMemo, useEffect, useState, useCallback, useRef } from "react";
import ThemeLayout from "../../layout/themeLayout";
import { useSelector, useDispatch } from "react-redux";
import { PLACEHOLDER } from "helpers/constant";
import { Link, useHistory, useParams } from "react-router-dom";
import Button from "../../component/Button/Button";
import { users as usersApi } from "../../api/users";
import UserPostCard from "../../Widgets/Posts/UserPostCard";
import UserChallengeCard from "../../Widgets/Posts/UserChallengeCard";
import UserJobCard from "../../Widgets/Posts/UserJobCard";
import styles from "./profile.module.css";
import { setUserChat } from "../../redux/actions/messages";
import RoundImage from "../../component/Image/RoundImage";
import SendNotification from "../../helpers/notification";
import { BANNER } from "../../helpers/constant";
import { Modal, Placeholder, Spinner } from "react-bootstrap";
import BackButton from "../../component/BackButton/BackButton";
import InfiniteScroll from "react-infinite-scroller";
import PaymentMethod from "../../Widgets/PaymentMethod";
import CoverImage from "../../component/Image/CoverImage";
import Icon from "../../component/Icon/Icon";
import MessageRequestModal from "../../component/MessageRequestModal/MessageRequestModal";

const Profile = () => {
    const history = useHistory();
    const auth_user = useSelector((store) => store.user.user);
    const dispatch = useDispatch();
    const [user, setUser] = useState({});
    const [posts, setPosts] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [jobs, setJobs] = useState([]);
    const { userId } = useParams();
    const [showModal, setShowModal] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedCard, setSelectedCard] = useState(null);

    const subscribeBtn = useRef();
    // const [postPagination, setPostPagination] = useState({
    //     page_no: 0,
    // });
    const postPagination = useRef({
        page_no: 0,
    });
    const postPages = useRef([]);
    // const [postPages, setPostPages] = useState([]);
    // const [challengePagination, setChallengePagination] = useState({
    //     page_no: 0,
    // });
    const challengePagination = useRef({
        page_no: 0,
    });
    // const [challengePages, setChallengePages] = useState([]);
    const challengePages = useRef([]);
    const jobPagination = useRef({
        page_no: 0,
    });
    const jobPages = useRef([]);
    const [showAddPayment, setShowAddPayment] = useState(false);
    const [showMessageRequestModal, setShowMessageRequestModal] = useState(false);

    const renderUserInterests = () => {
        return user.interests?.map((ui) => {
            return (
                <li className="mx-1" style={{ display: "inline-block", listStyle: "none" }} key={ui.id}>
                    <span className="p-1 badge badge-info">{ui.title}</span>
                </li>
            );
        });
    };

    // update post
    const updatePost = (post) => {
        setPosts((p) => p.map((p) => (p.id === post.id ? post : p)));
    };

    // user post func
    const getUserPosts = useCallback(
        async (userId = "", page_no = 1, per_page = 6) => {
            try {
                let param_path = userId ? userId?.toString() + "/posts" : "posts";

                const param = {
                    params: {
                        per_page,
                        page_no,
                    },
                    isBackground: false,
                    param_path,
                };
                const response = await dispatch(usersApi(param));
                if (response.success) {
                    if (!postPages?.current?.includes(response.pagination_data.current_page)) {
                        setPosts((p) => p.concat(response.data));
                        postPagination.current = response.pagination_data;
                        // setPostPagination(response.pagination_data);
                        let pp = postPages.current;
                        pp.push(response.pagination_data.current_page);
                        // setPostPages(pp);
                        postPages.current = pp;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        [dispatch, user],
    );

    const removePost = (id) => {
        setPosts((p) => p.filter((p) => p.id !== id));
    };

    const renderUserPosts = useMemo(() => {
        const renderPostItem = () => {
            if (posts?.length == 0) {
                return (
                    <div className="main-wraper">
                        <div className="text-center">No post found</div>
                    </div>
                );
            }
            return (
                <div className={`${styles[" merged-10"]} row merged-10`}>
                    {posts.map((p, i) => (
                        <div className={`${styles["col-md-4"]} col-md-4`} key={i}>
                            <UserPostCard post={p} remove={removePost} update={updatePost} />
                        </div>
                    ))}
                </div>
            );
        };

        const onEndReach = () => {
            getUserPosts(user.id, postPagination?.current?.current_page + 1, 6);
        };

        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={onEndReach}
                hasMore={postPagination?.current?.current_page < postPagination?.current?.last_page}
                loader={<Spinner key="Loadmore" type="LoadMore" />}
                threshold={50}
                useWindow={true}
            >
                {renderPostItem()}
            </InfiniteScroll>
        );
    }, [user, posts, postPagination.current]);

    // user challenges func
    const getUserChallenges = useCallback(
        async (userId, page_no = 1, per_page = 6) => {
            dispatch(
                usersApi({
                    params: {
                        per_page,
                        page_no,
                    },
                    isBackground: false,
                    param_path: userId + "/challenges",
                }),
            )
                .then((response) => {
                    if (response.success) {
                        if (!challengePages?.current?.includes(response.pagination_data.current_page)) {
                            setChallenges((p) => p.concat(response.data));
                            challengePagination.current = response.pagination_data;
                            let pp = challengePages.current;
                            pp.push(response.pagination_data.current_page);
                            challengePages.current = pp;
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        [dispatch, user],
    );

    const deleteJob = (id) => {
        setJobs((p) => p.filter((p) => p.id !== id));
    };

    const renderUserChallenges = useMemo(() => {
        const renderChallengeItem = () => {
            if (challenges.length == 0) {
                return (
                    <div className="main-wraper">
                        <div className="text-center">No challenges found</div>
                    </div>
                );
            }
            return challenges?.map((c) => (
                <div className="card shadow-sm rounded border-light my-3 p-0" key={c.id}>
                    <div className={`row m-0`}>
                        <UserChallengeCard
                            onDelete={(id) => {
                                setChallenges((p) => p.filter((c) => c.id !== id));
                            }}
                            challenge={c}
                        />
                    </div>
                </div>
            ));
        };

        const onEndReach = () => {
            getUserChallenges(user.id, challengePagination?.current?.current_page + 1, 6);
        };

        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={onEndReach}
                hasMore={challengePagination?.current?.current_page < challengePagination?.current?.last_page}
                loader={<Spinner key="Loadmore" type="LoadMore" />}
                threshold={50}
                useWindow={true}
            >
                {renderChallengeItem()}
            </InfiniteScroll>
        );
    }, [user, challenges, challengePagination.current]);

    // user jobs func
    const getUserJobs = useCallback((userId, page_no = 1, per_page = 6) => {
        dispatch(
            usersApi({
                params: {
                    per_page,
                    page_no,
                },
                isBackground: false,
                param_path: userId + "/jobs",
            }),
        )
            .then((response) => {
                if (response.success) {
                    if (!jobPages?.current.includes(response.pagination_data.current_page)) {
                        setJobs((p) => p.concat(response.data));
                        jobPagination.current = response.pagination_data;
                        let pp = jobPages.current;
                        pp.push(response.pagination_data.current_page);
                        jobPages.current = pp;
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, user]);

    const renderUserJobs = useMemo(() => {
        const renderJobItem = () => {
            if (jobs.length == 0) {
                return (
                    <div className="main-wraper">
                        <div className="text-center">No jobs found</div>
                    </div>
                );
            }
            return jobs?.map((j) => (
                <UserJobCard key={j.id} job={j} onUpdate={(job) => setJobs((p) => p.map((p) => (p.id === job.id ? job : p)))} onDelete={(id) => setJobs((p) => p.filter((p) => p.id !== id))} />
            ));
        };

        const onEndReach = () => {
            getUserJobs(user.id, jobPagination?.current?.current_page + 1, 6);
        };

        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={onEndReach}
                hasMore={jobPagination?.current.current_page < jobPagination?.current.last_page}
                loader={<Spinner key="Loadmore" type="LoadMore" />}
                threshold={50}
                useWindow={true}
            >
                {renderJobItem()}
            </InfiniteScroll>
        );
    }, [user, jobs, jobPagination.current]);

    const getUserDetails = useCallback(
        async (userId = "") => {
            try {
                const params = {
                    param_path: userId,
                };
                const response = await dispatch(usersApi(params));
                console.log('setting user');
                setUser(response.data);
            } catch (err) {
                console.log(err.response);
            }
        },
        [dispatch],
    );

    const openMessages = () => {
        if (user.id) {
            dispatch(setUserChat(`${user.id}|${user.name}`, `${user.profile_pic}`));
        }
    };

    useEffect(() => {
        if (user.id && postPagination.page_no >= 0) {
            getUserPosts(user.id, postPagination?.current?.page_no + 1, 6);
        }
    }, [user, postPagination.current]);

    useEffect(() => {
        if (user.id && challengePagination?.current.page_no >= 0) {
            getUserChallenges(user.id, challengePagination?.current?.page_no + 1, 6);
        }
    }, [user, challengePagination.current]);

    useEffect(() => {
        if (user.id && jobPagination?.current.page_no >= 0) {
            getUserJobs(user.id, jobPagination?.current.page_no + 1, 6);
        }
    }, [user, jobPagination.current]);

    useEffect(() => {
        if (user?.status == "deactivate" || user?.status == "deleted" || user?.status == "blocked") {
            history.replace(`/notfound`);
        }

        if (user.id) {
            setPosts([]);
            // setPostPages([]);
            postPages.current = [];
            postPagination.current = {
                per_page: 0
            }
            // setPostPagination({
            //     per_page: 0
            // });

            setChallenges([]);
            challengePages.current = [];
            challengePagination.current = {
                per_page: 0
            };

            setJobs([]);
            jobPages.current = [];
            jobPagination.current = {
                per_page: 0
            }

            getUserPosts(user.id);
            getUserChallenges(user.id);
            getUserJobs(user.id);
        }
    }, [user]);

    useEffect(() => {
        if (userId) {
            getUserDetails(userId);
        } else {
            setUser(auth_user);
        }
    }, [userId, auth_user]);

    const deleteChallenge = (id) => {
        setChallenges((p) => p.filter((c) => c.post.id == id));
    };

    const followUser = () => {
        dispatch(
            usersApi({
                method: "POST",
                param_path: user?.id.toString() + "/follow",
            }),
        )
            .then((response) => {
                setUser(response.data.user);
                if (response.data.notification) {
                    SendNotification(response.data.notification);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const addSubscription = () => {
        if (user?.is_subscribed) {
            dispatch(
                usersApi({
                    method: "POST",
                    param_path: user?.id.toString() + "/subscribe",
                }),
            )
                .then((response) => {
                    setUser(response.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            setSelectedCard(auth_user?.card_details[0]?.id);
            setShowModal(true);
        }
    };

    const subscribeUser = useCallback(() => {
        subscribeBtn.current.showLoader(true);
        dispatch(
            usersApi({
                method: "POST",
                param_path: user?.id?.toString() + "/subscribe",
                data: {
                    card_id: selectedCard,
                },
            }),
        )
            .then((response) => {
                subscribeBtn.current.showLoader(false);
                setUser(response.data);
                setShowModal(false);
            })
            .catch((e) => {
                subscribeBtn.current.showLoader(false);
                console.log(e);
            });
    }, [dispatch, user, selectedCard]);
    const openRequestMsgModal = () => {
        setShowMessageRequestModal(true);
    };
    return (
        <div>
            <Modal size="lg" id="submit-modal" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header className="p-3 border-bottom">
                    <h4 className="modal-title">Select Payment Method</h4>
                    <Button className="close" type="link" onClick={() => setShowModal(false)}>
                        x
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {auth_user?.card_details?.length > 0 ? (
                        <>
                            <select className="form-control mb-3" name="selected_card" defaultValue={selectedCard} onChange={(e) => setSelectedCard(e.target.value)}>
                                {auth_user?.card_details?.map((c) => (
                                    <option key={c.id} value={c.id}>
                                        {c.number} - {c.name}
                                    </option>
                                ))}
                            </select>

                            <span className="text-small font-weight-bold">
                                * You will be charged ${user?.subscription_charges} for this subscription {user?.subscription_frequency}
                            </span>
                        </>
                    ) : (
                        <>
                            <label className="form-label">No card found</label>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {auth_user?.card_details?.length > 0 ? (
                        <>
                            <Button type="button" ref={subscribeBtn} className="btn btn-primary" onClick={subscribeUser}>
                                Subscribe
                            </Button>
                            <Button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                        </>
                    ) : (
                        <Button
                            type="button"
                            className="btn btn-primary d-block"
                            onClick={() => {
                                setShowAddPayment(true);
                            }}
                        >
                            Add Card
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <Modal size="lg" id="submit-modal" show={showAddPayment} onHide={() => setShowAddPayment(false)} backdrop="static">
                <Modal.Header className="p-3 border-bottom">
                    <h4 className="modal-title">Add Payment Method</h4>
                    <Button className="close" htmlType="link" onClick={() => setShowAddPayment(false)}>
                        x
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <PaymentMethod auth_user={auth_user} addCard={() => setShowAddPayment(false)} />
                </Modal.Body>
            </Modal>

            <MessageRequestModal
                showModal={showMessageRequestModal}
                title="Send a message request"
                onClose={(t = false) => {
                    setShowMessageRequestModal(false);

                    if (t) {
                        setUser(t);
                    }
                }}
                size="md"
                centered={true}
                chatStatus={user?.chat_status}
                user={user}
            />
            <ThemeLayout>
                <div className={`container`}>
                    <div className={`gap d-none no-gap`}>
                        <div className={`${styles["top-area"]} top-area mate-black low-opacity`}>
                            <div
                                className={`${styles["bg-image"]} bg-image`}
                                style={{
                                    backgroundImage: `url('${user?.cover_pic || BANNER}')`,
                                }}
                            />
                            <div className={`container`}>
                                <div className={`row`}>
                                    <div className={`col-md-12`}>
                                        <div className={`${styles["post-subject"]} post-subject d-md-flex d-sm-block text-sm-center align-items-center px-2 py-5`}>
                                            <RoundImage src={`${user?.profile_pic || PLACEHOLDER}`} alt={user?.name} size="100px" />
                                            <div className={`${styles["uni-name"]} uni-name p-sm-0 mr-auto`}>
                                                <h4>{user?.name}</h4>
                                                <span>@{user?.username}</span>
                                                <div className="text-white d-md-flex user-stats-counts">
                                                    <div className="no-of-posts count">
                                                        <b className="">{user?.no_posts} </b>Posts
                                                    </div>

                                                    <div className="no-of-followers count">
                                                        <b className="">{user?.no_followers} </b>Followers
                                                    </div>

                                                    <div className="no-of-following count ">
                                                        <b className="">{user?.no_following} </b>Following
                                                    </div>

                                                    <div className="no-subscribers count">
                                                        <b className="">{user?.no_subscribers} </b>Subscribers
                                                    </div>

                                                    {/* {" "}
                                                    <b className="mr-2">{user?.no_posts} </b> Posts -{" "}
                                                    {user?.no_followers > 0 ? (
                                                        <Link to={`/all/followers/${user?.id}`}>
                                                            <b>{user?.no_followers}</b> Followers -{" "}
                                                        </Link>
                                                    ) : (
                                                        <>0 Followers - </>
                                                    )}{" "}
                                                    {user?.no_following > 0 ? (
                                                        <Link to={`/all/following/${user?.id}`}>
                                                            <b>{user?.no_following}</b> Following{" "}
                                                        </Link>
                                                    ) : (
                                                        <>0 Following</>
                                                    )}
                                                    {user?.no_subscribers > 0 && (
                                                        <Link to={`/all/subscribers/${user?.id}`}>
                                                            <b> - {user?.no_subscribers}</b> Subscribers
                                                        </Link>
                                                    )} */}
                                                </div>
                                            </div>

                                            {!!user.id && user.id == auth_user.id && (
                                                <Link className={`${styles["invite"]} btn btn-sm bg-light px-3 py-2 rounded-pill mt-sm-3`} to={`/profile/edit/${user.id}`}>
                                                    <i className="icofont-edit" />
                                                    Edit Profile
                                                </Link>
                                            )}
                                            {!!user.id && user.status == "deactivate" && user.id != auth_user.id && (
                                                <div className={`${styles["invite"]} btn btn-sm bg-light px-3 py-2 rounded-pill mt-sm-3`}>
                                                    <i className="icofont-ui-block" /> Deactivated
                                                </div>
                                            )}
                                            {user.status == "active" && user?.id != auth_user?.id && (
                                                <a className={`${styles["invite"]} btn btn-sm bg-light px-3 py-2 rounded-pill mt-sm-3`} onClick={openMessages}>
                                                    <i className="icofont-email" /> Message
                                                </a>
                                            )}
                                            {/* <div className={`${styles["university-tag mb-5"]} university-tag mb-5`}>
                                            </div> */}
                                        </div>
                                        <div className={`${styles["post-subject"]} post-subject d-none`}>
                                            <div className={`${styles["university-tag mb-5"]} university-tag mb-5`}>
                                                <RoundImage src={`${user?.profile_pic || PLACEHOLDER}`} alt={user?.name} size="100px" />
                                                <div className={`${styles["uni-name"]} uni-name`}>
                                                    <h4>{user?.name}</h4>
                                                    <span>@{user?.username}</span>
                                                    <br />
                                                    <span>
                                                        {" "}
                                                        <b>{user?.no_posts} </b> Posts -{" "}
                                                        {user?.no_followers > 0 ? (
                                                            <Link to={`/all/followers/${user?.id}`}>
                                                                <b>{user?.no_followers}</b> Followers -{" "}
                                                            </Link>
                                                        ) : (
                                                            <>0 Followers - </>
                                                        )}{" "}
                                                        {user?.no_following > 0 ? (
                                                            <Link to={`/all/following/${user?.id}`}>
                                                                <b>{user?.no_following}</b> Following{" "}
                                                            </Link>
                                                        ) : (
                                                            <>0 Following</>
                                                        )}
                                                        {user?.no_subscribers > 0 && (
                                                            <Link to={`/all/subscribers/${user?.id}`}>
                                                                <b> - {user?.no_subscribers}</b> Subscribers
                                                            </Link>
                                                        )}
                                                    </span>
                                                </div>
                                                {user.id && user.id == auth_user.id ? (
                                                    <Link className={`${styles["invite"]} invite`} to={`/profile/edit/${user.id}`}>
                                                        <i className="icofont-edit" />
                                                        Edit Profile
                                                    </Link>
                                                ) : (
                                                    <a className={`${styles["invite"]} invite`} onClick={openMessages}>
                                                        <i className="icofont-email" /> Message
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* top Head */}

                    <div className="profile-header-section">
                        <CoverImage
                            className="rounded"
                            bgrepeat="no-repeat"
                            bgsize="cover"
                            bgposition={`center`}
                            bgsrc={`url(${user?.cover_pic || null})`}
                            width="100%"
                            height="350px"
                            bgcolor="rgb(0 0 0 / 50%)"
                        >
                            <div className="d-flex h-100 align-items-end p-4 p-sm-0 flex-sm-column align-items-sm-center justify-content-sm-center text-sm-center">
                                <div className="profile-picture-section d-lg-flex align-items-center ">
                                    <RoundImage className={`img-thumbnail`} src={`${user?.profile_pic || PLACEHOLDER}`} alt={user?.name} size="100px" />
                                    <div className="basic-info-section ml-3">
                                        <div className="display-name d-flex align-items-center">
                                            <h4 className="text-white mr-1">{user?.name}</h4>{" "}
                                            {user?.type == "hunter" && (
                                                <span>
                                                    <img src="/public/images/resources/blue-tick.png" alt="verified-user" style={{ width: "15px" }} />
                                                </span>
                                            )}
                                        </div>
                                        <div className="user-name-name">
                                            <span className="text-white">@{user?.username}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-actions-section ml-auto mx-sm-auto my-0 my-sm-4">
                                    {auth_user.id != user.id && (
                                        <Button className="btn btn-sm btn-light rounded-pill mb-sm-2" type="button" onClick={() => followUser()}>
                                            {user?.is_following ? "Unfollow" : "Follow"}
                                        </Button>
                                    )}
                                    {auth_user.id != user.id && user?.subscription_level >= 1 && (
                                        <Button className="btn btn-sm btn-light rounded-pill mb-sm-2 ml-2" type="button" onClick={() => addSubscription()}>
                                            {user?.is_subscribed ? "Unsubscribe" : "Subscribe"}
                                        </Button>
                                    )}

                                    {!!user.id && user.id == auth_user.id && (
                                        <Link className={`btn btn-sm btn-light rounded-pill mb-sm-2 ml-2`} to={`/profile/edit/${user.id}`}>
                                            <i className="icofont-edit" />
                                            Edit Profile
                                        </Link>
                                    )}
                                    {!!user.id && user.status == "deactivate" && user.id != auth_user.id && (
                                        <div className={`btn btn-sm btn-light rounded-pill mb-sm-2 ml-2`}>
                                            <i className="icofont-ui-block" /> Deactivated
                                        </div>
                                    )}

                                    {user?.chat_status != "impossible" && (
                                        <>
                                            {user.status == "active" && user?.id != auth_user?.id && (
                                                <a
                                                    className={`btn btn-sm btn-light rounded-pill mb-sm-2 ml-2`}
                                                    onClick={user?.chat_status == `not-contacted` || user?.chat_status == `requested` ? openRequestMsgModal : openMessages}
                                                >
                                                    <i className="icofont-email" /> Message
                                                </a>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </CoverImage>
                    </div>
                    <div className="profile-main-section">
                        {/* sidebar of profile */}
                        <div className="row m-auto">
                            <div className="col-lg-3 p-0">
                                <BackButton />

                                <div className="about-section text-center h-100">
                                    <div className="card bg-light border-0 py-3 h-100">
                                        <div className={` ${user?.type == "hunter" ? "text-left" : "text-center"} box`}>
                                            <div className={`card-header one border-0 py-0 px-2 bg-transparent`}>
                                                <h5 className="card-title mb-0 font-weight-bold">{user?.type == "hunter" ? "About" : "Bio"}</h5>
                                                <div className={`${user?.type == "hunter" ? "m-2" : ""} center-ball-separator-hr`}></div>
                                            </div>

                                            {/* Card body START */}
                                            <div className="py-0 px-2">
                                                {user?.type == "hunter" ? (
                                                    <div className="hunter-basic-detail">

                                                        {user?.intro && <p className="mb-0 font-italic font-weight-bold">{user?.intro}</p>}
                                                        {user?.email && <div className="user-email">
                                                            <Icon className={`mr-1 text-info`} name={`envelope`} />
                                                            <small>
                                                                <Link to={`mailto:${user?.email}`}>{user?.email}</Link>
                                                            </small>
                                                        </div>}
                                                        {user?.dob && <div className="acc-created-date">
                                                            <Icon className={`mr-1 text-info`} name={`ui-calendar`} />
                                                            <small>{user?.dob}</small>
                                                        </div>}
                                                        {user?.website && <div className="acc-website">
                                                            <Icon className={`mr-1 text-info`} name={`globe-alt`} />
                                                            <small><a href={`${user?.website}`} target={`_blank`}>{user?.website}</a></small>
                                                        </div>}
                                                        {user?.phone_no && <div className="user-phone">
                                                            <Icon className={`mr-1 text-info`} name={`ui-call`} />
                                                            <small>
                                                                <Link to={`tel:${user?.phone_no}`}>{user?.phone_no}</Link>
                                                            </small>
                                                        </div>}

                                                        {user?.address && <div className="user-address">
                                                            <Icon className={`mr-1 text-info`} name={`google-map`} />
                                                            <small>
                                                                {user?.address}
                                                            </small>
                                                        </div>}
                                                        {user?.contact_details && <div className="contact-persons mb-3">
                                                            <b className="d-block mt-2 mb-0">Contact details</b>
                                                            <div className="persons-sec">
                                                                <div className="d-flex align-items-start">
                                                                    {/* <RoundImage className={`mr-1`} src={PLACEHOLDER} size={`15px`} /> */}
                                                                    <div className="person-detail" style={{ lineHeight: "14px" }}>
                                                                        <small className="p-name d-block">{user?.contact_details}</small>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>}
                                                    </div>
                                                ) : (
                                                    <div className="user-basic-detail">
                                                        <p className="mb-0 font-italic font-weight-bold">"{user?.intro}"</p>
                                                    </div>
                                                )}
                                                <div className="user-stats mt-2">
                                                    <div className={`${user?.type == "hunter" ? `justify-content-start` : `justify-content-center`} row m-auto `} style={{}}>
                                                        {/* User stat item */}
                                                        <div className="item col-6 cursor-pointer" style={{ background: "#fff", padding: "10px", flex: "0 0 45%", margin: "1px" }}>
                                                            <span className="mb-0 d-block" style={{ fontSize: "25px" }}>
                                                                {user?.no_posts}
                                                            </span>
                                                            <span className="text-info font-weight-bold" style={{ fontSize: "15px" }}>
                                                                Posts
                                                            </span>
                                                        </div>

                                                        {/* User stat item */}
                                                        <div
                                                            className="item col-6 cursor-pointer"
                                                            style={{ background: "#fff", padding: "10px", flex: "0 0 45%", margin: "1px" }}
                                                            onClick={() => {
                                                                if (user?.no_followers > 0) {
                                                                    history.push(`/all/followers/${user?.id}`);
                                                                }
                                                            }}
                                                        >
                                                            <span className="mb-0 d-block" style={{ fontSize: "25px" }}>
                                                                {user?.no_followers}
                                                            </span>
                                                            <span className="text-info font-weight-bold" style={{ fontSize: "15px" }}>
                                                                Followers
                                                            </span>
                                                        </div>

                                                        {/* User stat item */}
                                                        <div
                                                            className="item col-6 cursor-pointer"
                                                            style={{ background: "#fff", padding: "10px", flex: "0 0 45%", margin: "1px" }}
                                                            onClick={() => {
                                                                if (user?.no_following > 0) {
                                                                    history.push(`/all/following/${user?.id}`);
                                                                }
                                                            }}
                                                        >
                                                            <span className="mb-0 d-block" style={{ fontSize: "25px" }}>
                                                                {user?.no_following}
                                                            </span>
                                                            <span className="text-info font-weight-bold">Following</span>
                                                        </div>

                                                        {/* User stat item */}
                                                        <div
                                                            className="item col-6 cursor-pointer"
                                                            style={{ background: "#fff", padding: "10px", flex: "0 0 45%", margin: "1px" }}
                                                            onClick={() => {
                                                                if (user?.no_subscribers > 0) {
                                                                    history.push(`/all/subscribers/${user?.id}`);
                                                                }
                                                            }}
                                                        >
                                                            <span className="mb-0 d-block" style={{ fontSize: "25px" }}>
                                                                {user?.no_subscribers}
                                                            </span>
                                                            <span className="text-info font-weight-bold" style={{ fontSize: "15px" }}>
                                                                Subscribers
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Card body END */}
                                        </div>
                                        <div className="box my-3">
                                            <div className="card-header one border-0 py-0 px-2 bg-transparent">
                                                <h5 className="card-title mb-0 font-weight-bold">Interested in</h5>
                                                <div className="center-ball-separator-hr"></div>
                                            </div>
                                            {/* Card body START */}
                                            <div className="py-0 px-2">
                                                {user?.interests?.length > 0 && (
                                                    <div className={`${styles["interests-sec"]} interests-sec`}>
                                                        <ul
                                                            className={`${styles["interests-sec-ul"]} interests-sec-ul mb-0`}
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            {renderUserInterests()}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            {/* Card body END */}
                                        </div>

                                        {user?.type != "hunter" && (
                                            <div className="box">
                                                <div className="card-header one border-0 py-0 px-2 bg-transparent">
                                                    <h5 className="card-title mb-0 font-weight-bold">Achievements</h5>
                                                    <div className="center-ball-separator-hr"></div>
                                                </div>
                                                {/* Card body START */}
                                                <div className="py-0 px-2">
                                                    <div className="achievements">
                                                        <div className="grid d-flex justify-content-center flex-wrap">
                                                            <div className="item col-6 col-sm-12 bg-white mb-2 shadow-sm p-2 rounded">
                                                                <RoundImage className={`img-thumbnail mb-2`} src={`/public/images/resources/winner-badge-gold.png`} size={`60px`} alt="achievements" />
                                                                <small className="d-block text-truncate" style={{ fontSize: "70%" }}>
                                                                    {" "}
                                                                    Winner in {user.challenges_won} challlenges
                                                                </small>
                                                                <Button
                                                                    className="btn btn-sm btn-light rounded-pill mt-2 mb-sm-2 border-white"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        history.push(`/all/winner/${user?.id}`);
                                                                    }}
                                                                >
                                                                    <Icon className={`text-danger mr-2`} name="eye-alt" />
                                                                    see all
                                                                </Button>
                                                            </div>

                                                            <div className="item col-6 bg-white col-sm-12 mb-2 shadow-sm p-2 rounded">
                                                                <RoundImage
                                                                    className={`img-thumbnail mb-2`}
                                                                    src={`/public/images/resources/winner-badge-silver.png`}
                                                                    size={`60px`}
                                                                    alt="achievements"
                                                                />
                                                                <small className="d-block text-truncate" style={{ fontSize: "70%" }}>
                                                                    {" "}
                                                                    Runner-up in {user.challenges_runnerup} challlenges
                                                                </small>
                                                                <Button
                                                                    className="btn btn-sm btn-light rounded-pill mt-2 mb-sm-2 border-white"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        history.push(`/all/runnerup/${user?.id}`);
                                                                    }}
                                                                >
                                                                    <Icon className={`text-danger mr-2`} name="eye-alt" />
                                                                    see all
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Card body END */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <>
                                    <div className="profile-create-action-section mb-3">
                                        {user.id && user.id == auth_user.id && (
                                            <>
                                                <div className="profile-create-action-btns">
                                                    <div className="card border-0 rounded-0 py-2 px-2">
                                                        <div className="">
                                                            <div className="d-lg-flex align-items-center justify-content-end text-sm-center">
                                                                <h5 className="mr-2">Create new</h5>
                                                                <Button className="btn btn-sm btn-light rounded-pill mr-2 mb-sm-2" type="button" onClick={() => history.push("/create-post")}>
                                                                    <Icon className={`text-danger mr-2`} name="video" />
                                                                    Post
                                                                </Button>

                                                                <Button className="btn btn-sm btn-light rounded-pill mr-2 mb-sm-2" type="button" onClick={() => history.push("/create-challenge")}>
                                                                    <Icon className={`text-warning mr-2`} name="trophy" />
                                                                    Challenge
                                                                </Button>

                                                                <Button className="btn btn-sm btn-light rounded-pill mr-2 mb-sm-2" type="button" onClick={() => history.push("/create-club")}>
                                                                    <Icon className={`text-success mr-2`} name="users-social" />
                                                                    Club
                                                                </Button>
                                                                {user?.type == "hunter" &&

                                                                    <Button className="btn btn-sm btn-light rounded-pill mr-2 mb-sm-2" type="button" onClick={() => history.push("/create-hunt")}>
                                                                        <Icon className={`text-danger mr-2`} name="briefcase" />
                                                                        Hunt
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="center-ball-separator-hr"></div>
                                            </>
                                        )}
                                    </div>

                                    <ul className={`${styles["nav"]} ${styles["nav-tabs"]} ${styles["nav-justified "]} ${styles["about-btn"]} nav nav-tabs nav-justified about-btn`}>
                                        <li onClick={() => setSelectedTab(0)} className={`nav-item`} title="Videos" data-toggle="tooltip">
                                            <Button className={`btn-block btn ${selectedTab == 0 ? "active btn-info" : "bg-light"}`} data-toggle="tab">
                                                <i className={`${styles["f-s-30"]} icofont-video`} />
                                            </Button>
                                        </li>
                                        <li onClick={() => setSelectedTab(1)} className={`nav-item`} title="Challenges" data-toggle="tooltip">
                                            <Button className={`btn-block btn ${selectedTab == 1 ? "active btn-info" : "bg-light"}`} data-toggle="tab">
                                                <i className={`${styles["f-s-30"]} icofont-flame-torch`} />
                                            </Button>
                                        </li>
                                        {user?.type == "hunter" &&
                                            <li onClick={() => setSelectedTab(2)} className={`nav-item`} title="Jobs" data-toggle="tooltip">
                                                <Button className={`btn-block btn ${selectedTab == 2 ? "active btn-info" : "bg-light"}`} data-toggle="tab">
                                                    <i className={`${styles["f-s-30"]} icofont-briefcase `} />
                                                </Button>
                                            </li>
                                        }
                                    </ul>
                                    <div className="tab-content mt-3">
                                        <div className={`${styles["tab-pane"]} ${styles["fade"]} tab-pane ${selectedTab == 0 ? "show active" : ""}`} id="follow">
                                            {renderUserPosts}
                                        </div>
                                        <div className={`${styles["tab-pane"]} ${styles["fade"]} tab-pane fade ${selectedTab == 1 ? "show active" : ""}`} id="about">
                                            {/* <div className="main-wraper"> */}
                                            {renderUserChallenges}
                                            {/* </div> */}
                                        </div>
                                        {user?.type == "hunter" &&
                                            <div className={`${styles["tab-pane"]} ${styles["fade"]} tab-pane fade ${selectedTab == 2 ? "show active" : ""}`} id="jobs">
                                                {renderUserJobs}
                                            </div>
                                        }
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                    <section>
                        <div className={`gap d-none`}>
                            <div className={`container`}>
                                <div className={`row`}>
                                    <div className={`col-md-12`}>
                                        <div id="page-contents" className={`row`}>
                                            <div className="col-md-12">
                                                <BackButton />

                                                {auth_user.id != user.id && (
                                                    <Button className="float-right mb-2" type="button" onClick={() => followUser()}>
                                                        {user?.is_following ? "Unfollow" : "Follow"}
                                                    </Button>
                                                )}
                                                {auth_user.id != user.id && user?.subscription_level >= 1 && (
                                                    <Button className="mr-2 float-right mb-2" type="button" onClick={() => addSubscription()}>
                                                        {user?.is_subscribed ? "Unsubscribe" : "Subscribe"}
                                                    </Button>
                                                )}
                                            </div>
                                            <div className={`col-md-12`}>
                                                <p className={`${styles[" about-p"]} m-0`}>{user?.intro}</p>
                                                {user?.interests?.length > 0 && (
                                                    <div className={`${styles["interests-sec"]} interests-sec`}>
                                                        <ul
                                                            className={`${styles["interests-sec-ul"]} interests-sec-ul`}
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                        >
                                                            <span>Interested in</span>
                                                            {renderUserInterests()}
                                                        </ul>
                                                    </div>
                                                )}
                                                {user.id && user.id == auth_user.id && (
                                                    <div className={`${styles["main-wraper"]} main-wraper mt-3`}>
                                                        <span className={`${styles["new-title"]} new-title`}>Create New</span>
                                                        <ul className={`${styles["upload-media"]} upload-media text-sm-center`}>
                                                            <li className={`mr-2 my-sm-2`}>
                                                                <Button type="link" onClick={() => history.push("/create-post")}>
                                                                    <i className={`mr-2`}>
                                                                        <svg
                                                                            style={{
                                                                                fill: "#deebf3",
                                                                                stroke: "#088dcd",
                                                                            }}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={18}
                                                                            height={18}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            className="feather feather-video"
                                                                        >
                                                                            <polygon points="23 7 16 12 23 17 23 7" />
                                                                            <rect x={1} y={5} width={15} height={14} rx={2} ry={2} />
                                                                        </svg>
                                                                    </i>
                                                                    Upload Video
                                                                </Button>
                                                            </li>
                                                            <li className={`mr-2 my-sm-2`}>
                                                                <Button type="link" onClick={() => history.push("/create-challenge")}>
                                                                    <i className={`mr-2`}>
                                                                        <svg
                                                                            style={{
                                                                                fill: "#deebf3",
                                                                                stroke: "#088dcd",
                                                                            }}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={18}
                                                                            height={18}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            className="feather feather-award"
                                                                        >
                                                                            <circle cx={12} cy={8} r={7} />
                                                                            <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
                                                                        </svg>
                                                                    </i>
                                                                    Create Challenge
                                                                </Button>
                                                            </li>
                                                            <li className={`mr-2 my-sm-2`}>
                                                                <Button type="link" onClick={() => history.push("/create-club")}>
                                                                    <i className={`mr-2`}>
                                                                        <svg
                                                                            style={{
                                                                                fill: "#deebf3",
                                                                                stroke: "#088dcd",
                                                                            }}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={18}
                                                                            height={18}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            className="feather feather-users"
                                                                        >
                                                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                                                            <circle cx={9} cy={7} r={4} />
                                                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                                                            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                                                        </svg>
                                                                    </i>
                                                                    Create Club
                                                                </Button>
                                                            </li>
                                                            <li className={`mr-2 my-sm-2`}>
                                                                <Button type="link" onClick={() => history.push("/create-hunt")}>
                                                                    <i className={`mr-2`}>
                                                                        <svg
                                                                            style={{
                                                                                fill: "#deebf3",
                                                                                stroke: "#088dcd",
                                                                            }}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={18}
                                                                            height={18}
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            className="feather feather-briefcase"
                                                                        >
                                                                            <rect x={2} y={7} width={20} height={14} rx={2} ry={2} />
                                                                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
                                                                        </svg>
                                                                    </i>
                                                                    Create Hunt
                                                                </Button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                                <ul className={`${styles["nav"]} ${styles["nav-tabs"]} ${styles["nav-justified "]} ${styles["about-btn"]} nav nav-tabs nav-justified about-btn`}>
                                                    <li onClick={() => setSelectedTab(0)} className={`nav-item`} title="Videos" data-toggle="tooltip">
                                                        <Button className={`btn-block btn ${selectedTab == 0 ? "active btn-info" : "bg-light"}`} data-toggle="tab">
                                                            <i className={`${styles["f-s-30"]} icofont-video`} />
                                                        </Button>
                                                    </li>
                                                    <li onClick={() => setSelectedTab(1)} className={`nav-item`} title="Challenges" data-toggle="tooltip">
                                                        <Button className={`btn-block btn ${selectedTab == 1 ? "active btn-info" : "bg-light"}`} data-toggle="tab">
                                                            <i className={`${styles["f-s-30"]} icofont-flame-torch`} />
                                                        </Button>
                                                    </li>
                                                    <li onClick={() => setSelectedTab(2)} className={`nav-item`} title="Jobs" data-toggle="tooltip">
                                                        <Button className={`btn-block btn ${selectedTab == 2 ? "active btn-info" : "bg-light"}`} data-toggle="tab">
                                                            <i className={`${styles["f-s-30"]} icofont-briefcase `} />
                                                        </Button>
                                                    </li>
                                                </ul>
                                                <div className="tab-content mt-3">
                                                    <div className={`${styles["tab-pane"]} ${styles["fade"]} tab-pane ${selectedTab == 0 ? "show active" : ""}`} id="follow">
                                                        {renderUserPosts}
                                                    </div>
                                                    <div className={`${styles["tab-pane"]} ${styles["fade"]} tab-pane fade ${selectedTab == 1 ? "show active" : ""}`} id="about">
                                                        {/* <div className="main-wraper"> */}
                                                        {renderUserChallenges}
                                                        {/* </div> */}
                                                    </div>
                                                    <div className={`${styles["tab-pane"]} ${styles["fade"]} tab-pane fade ${selectedTab == 2 ? "show active" : ""}`} id="jobs">
                                                        {renderUserJobs}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </ThemeLayout>
        </div>
    );
};

export default Profile;
