import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { messages } from "../../api/messages";
import { toast } from "react-toastify";

const MessageRequestModal = (props) => {
    const { showModal, onClose, chatStatus } = props;
    const notesRef = useRef("");
    const dispatch = useDispatch();

    const sendMessageRequest = () => {
        let notes = notesRef.current.value;
        if (!!notes == false){
            return;
        }

        const formData = new FormData();
        formData.append("notes", notesRef.current.value);

        if (!!notes == true) {
            dispatch(
                messages({
                    param_path: `${props.user.id}/request`,
                    data: formData,
                    method: "POST",
                }),
            )
                .then((response) => {
                    if (response.success) {
                        onClose(response.data);
                    } else {
                        onClose();
                    }
                })
                .catch((e) => {
                    console.log(e);
                    onClose();
                });
        }
    };

    return (
        <Modal centered={props.centered || false} size={props.size || `lg`} id="submit-modal" show={showModal} onHide={() => onClose()} backdrop="static">
            <Modal.Header className="p-3 border-bottom">
                <h4 className="modal-title">{props.title}</h4>
                <Button className="close" type="link" onClick={() => onClose()}>
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                {chatStatus == "not-contacted" && (
                    <>
                        <div className="form-group">
                            <label htmlFor="">You cannot send message to this user. Please enter a custom message or include a personal note.</label>
                            <textarea ref={notesRef} className="form-control" rows="3"></textarea>
                        </div>
                    </>
                )}

                {chatStatus == "requested" && <p>You message request has been sent.</p>}
            </Modal.Body>
            {chatStatus == "not-contacted" && (
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-end">
                        <Button type="button" className="btn btn-sm btn-danger mr-2">
                            Cancel
                        </Button>
                        <Button type="button" className="btn btn-sm" onClick={sendMessageRequest}>
                            Send
                        </Button>
                    </div>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default MessageRequestModal;
