import React from 'react';

const Image = React.memo((props) => {
    
    const onLoad = async () => {

    };

    return (
        <img src={props.src} alt={props.alt || "#"} onLoad={onLoad} />
    )
});

export default Image;